<template>
  <div class="lineGroup">
    <graphTitle :title="'各区域欠费趋势'">
      <el-tooltip placement="top"
                  content="展示统计期间内各生成的订单截止至最近统计时刻的欠费金额趋势">
        <i class="el-icon-question my-icon" />
      </el-tooltip>
        <span style="margin-left:10px">最近统计时刻: {{lastCountTime}}</span>
    </graphTitle>
    <div id="lineGraph">
    </div>
  </div>
</template>
<script>
import graphTitle from "@/components/graphTitle";
export default {
  data() {
    return {
      // cityNameList: ['海淀区', '朝阳区'],
      // dateList: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
      // dataDetailList: [{ name: '海淀区', type: 'line', data: [120, 132, 101, 134, 90, 230, 210] }, { name: '朝阳区', type: 'line', data: [120, 132, 101, 134, 90, 230, 210] }]
      cityNameList: [0],
      dateList: [0],
      dataDetailList: [{ name: 0, type: 'line', data: [0] }],
      lastCountTime: '',

    }
  },
  components: {
    graphTitle
  },
  methods: {
    drawGraph() {
      let that = this;
      let lineGraph = this.$echarts.init(document.getElementById('lineGraph'));
      let option = {
        title: {
          text: ''
        },
        color: this.$colorList,
        tooltip: {
          trigger: 'axis',
          formatter: (parmas) => {
            let str = ''
            str += parmas[0].axisValue + '<br/>'
            parmas.forEach(e => {
              str += `${e.marker} ${e.seriesName}: ${e.value}(元)<br/>`
            })
            return str
          }
        },
        legend: {
          data: that.cityNameList,
          orient: 'horizontal',
          type: 'scroll',
          // bottom: -5,
          width: '80%',
          selectedMode: 'multiple'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        toolbox: {

        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: that.dateList,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          name: '欠费金额(元)',
          type: 'value',
          position: 'left',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        series: that.dataDetailList,
      };
      lineGraph.setOption(option, true);
    }
  },
  mounted() {
    this.drawGraph();
  },
  created() {
    this.$EventBus.$on('getArrearageAmount', (data) => {
      let lineData = data.list ?? [];
      this.lastCountTime = data.time;
      console.log(data)
      if (lineData.length > 0) {
        this.cityNameList = [];
        this.dateList = [];
        this.dataDetailList = [];
        for (var k = 0; k < lineData[0].cityData.length; k++) {
          this.dateList.push(lineData[0].cityData[k].dataDate)
        }
        for (var i = 0; i < lineData.length; i++) {
          this.cityNameList.push(lineData[i].areaName);
          let arrearageAmount = [];
          for (var j = 0; j < lineData[i].cityData.length; j++) {
            arrearageAmount.push((lineData[i].cityData[j].arrearageAmount / 100).toFixed(2))
          }
          this.dataDetailList.push({ name: lineData[i].areaName, type: 'line', data: arrearageAmount })
        }
      } else {
        this.cityNameList = [0];
        this.dateList = [0];
        this.dataDetailList = [{ name: 0, type: 'line', data: [0] }];
      }
      this.drawGraph();
    })
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.lineGroup {
  #lineGraph {
    height: 400px;
  }

  .region {
    text-align: center;
  }
}
</style>
