var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lineGroup" },
    [
      _c(
        "graphTitle",
        { attrs: { title: "各区域欠费趋势" } },
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                placement: "top",
                content:
                  "展示统计期间内各生成的订单截止至最近统计时刻的欠费金额趋势",
              },
            },
            [_c("i", { staticClass: "el-icon-question my-icon" })]
          ),
          _c("span", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v("最近统计时刻: " + _vm._s(_vm.lastCountTime)),
          ]),
        ],
        1
      ),
      _c("div", { attrs: { id: "lineGraph" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }